<template>
  <div class="top-right-bar">
    <div @click="onBackClick" class="icon">
      <span v-if="!empty">
        <i class="bi bi-caret-left"></i>
        {{ title }}
      </span>
    </div>
    <div @click="onRightButtonClick" class="icon">
      <i class="bi" :class="rightButtonIcon"></i>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props: {
    title: String,
    empty: Boolean,
    rightButtonIcon: String,
    onRightButtonClick: Function,
    // emit: String,
  },

  methods: {
    ...mapActions('chat', ['setActiveChatAndRoute']),
    onBackClick() {
      this.setActiveChatAndRoute()
      this.$router.push({ name: 'chat-app' })
    },
  },
}
</script>

<style lang="scss" scoped>
.top-right-bar {
  overflow: hidden;
  padding: 3px;
  background-color: $secondaryBackgroundColor;
  border-bottom: 2px solid $secondaryBackgroundColor;
  display: grid;
  grid-template-columns: auto 30px;
  align-content: center;
  align-items: center;
  justify-items: start;

  .icon {
    cursor: pointer;

    span {
      display: flex;
      align-items: center;
      gap: 0.6rem;
    }
  }

  .title {
    font-size: 0.8rem;
    font-weight: bold;
  }
}
</style>
