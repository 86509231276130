<template>
  <div class="chat-list-item" :class="{ 'is-active': isActive }">
    <div class="left">
      <div class="photo">
        <span v-html="createPhoto(project.id, 'bottts')"></span>
      </div>
    </div>
    <div class="right">
      <div class="name ellipsis">{{ title }}</div>
      <div class="last-message ellipsis"></div>
      <div class="date"></div>
    </div>
  </div>
</template>

<script>
import { createPhoto } from '@/utils/chatUtils.js'

export default {
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    photo: {
      type: String,
    },
    project: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    createPhoto,
  },
}
</script>

<style lang="scss">
.photo {
  svg {
    width: 45px;
    height: 45px;
  }
}
</style>

<style lang="scss" scoped>
.chat-list-item {
  cursor: pointer;
  user-select: none;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 3.5rem auto;
  align-items: center;
  padding: 7px 5px;
  border-left: 3px solid transparent;
  border-bottom: 1px solid $secondaryBackgroundColor;
  // background-color: $secondaryBackgroundColor;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  &.is-active {
    border-left: 3px solid $accentColor;
    background-color: $backgroundColor;
  }

  &:hover {
    opacity: 0.8;
    background-color: $backgroundColor;
  }

  .left {
    overflow: hidden;
    .photo {
      display: grid;
      justify-content: center;
      text-align: center;
      padding-right: 5px;
      position: relative;

      img {
        height: 45px;
        width: 45px;
        border: 3px solid $borderColor;
        border-radius: 50%;
        background-color: $alternativeBackgroundColor;

        &.unread {
          border-color: $accentColor;
        }
      }

      .unread-badge {
        background: #ff7373;
        width: 10px;
        height: 10px;
        position: absolute;
        border-radius: 50%;
        border: 2px solid #364e00;
        text-align: center;
        font-size: 0.7rem;
        font-weight: bold;
      }
    }
  }

  .right {
    overflow: hidden;

    .name {
      font-weight: bold;
    }

    .last-message {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 0.8rem;
    }

    .date {
      font-size: 0.7rem;
      color: $secondaryTextColor;
    }
  }
}
</style>
