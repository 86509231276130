import { ref } from 'vue'
import { useStore } from 'vuex'

export default function useSocket() {
  const connection = ref()
  const store = useStore()

  const onclose = async (e) => store.dispatch('chat/onSocketClosed', e)
  const onerror = async (e) => console.log('A socket error occurred: ', e)

  const onmessage = async (e) => {
    let socketData = JSON.parse(e.data)
    let type = socketData.type
    let data = socketData.data

    if (type == 'chat.message') {
      return await store.dispatch('chat/handleNewMessage', data)
    }

    if (type == 'chat.created') {
      return await store.dispatch('chat/handleNewChat', data)
    }

    if (type == 'announcement') {
      if (data.type == 'message_status_change') {
        return await store.dispatch('chat/handleMessageStatusChange', data)
      }
    }
  }

  const markAsRead = async (chatId) => {
    await connection.value.send(
      JSON.stringify({
        type: 'chat.read',
        data: { chat_id: chatId },
      })
    )
  }

  const initWebSocket = (projectId) => {
    const protocol = window.location.protocol === 'https:' ? 'wss://' : 'ws://'
    connection.value = new WebSocket(
      protocol + window.location.host + '/ws/' + projectId + '/'
    )

    connection.value.onclose = onclose
    connection.value.onerror = onerror
    connection.value.onmessage = onmessage
    connection.value.markAsRead = markAsRead

    store.dispatch('chat/onSocketCreated')
    return connection
  }

  return { initWebSocket }
}
