<template>
  <div
    v-if="latestMessage"
    :class="{ 'is-active': isActive, unread: isUnread }"
    class="chat-list-item"
  >
    <div class="left">
      <div class="photo">
        <div v-if="isUnread" class="unread-badge"></div>
        <img
          v-if="chat.photo"
          :class="{ unread: isUnread }"
          :src="chat.photo"
        />
        <span
          v-else
          v-html="createPhoto(chat.id)"
          :class="{ unread: isUnread }"
        >
        </span>
      </div>
    </div>
    <div class="right">
      <div class="name ellipsis">{{ chat.full_name }}</div>
      <div class="last-message ellipsis">
        {{ latestMessage.text || latestMessage.caption || '...' }}
      </div>
      <div class="date">
        {{ getMessageTime(latestMessage.created_at, true) }}
      </div>
    </div>
  </div>
</template>

<script>
import { getMessageTime } from '@/utils/messageUtils.js'
import { createPhoto } from '@/utils/chatUtils.js'
import { mapState } from 'vuex'

export default {
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    chat: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapState('chat', ['messages']),
    isUnread() {
      return this.chat.is_unread
    },
    latestMessage: function () {
      const messages = this.messages[this.chat.id]
      return messages[0] || this.chat.latest_message
    },
  },
  methods: {
    getMessageTime,
    createPhoto,
  },
}
</script>

<style lang="scss">
.photo {
  svg {
    width: 45px;
    height: 45px;
  }
}
</style>

<style lang="scss" scoped>
.chat-list-item {
  cursor: pointer;
  user-select: none;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 3.5rem auto;
  align-items: center;
  padding: 7px 5px;
  border-left: 3px solid transparent;
  border-bottom: 1px solid $secondaryBackgroundColor;
  // background-color: $secondaryBackgroundColor;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  &.is-active {
    border-left: 3px solid $accentColor;
    background-color: $backgroundColor;
  }

  &:hover {
    opacity: 0.8;
    background-color: $backgroundColor;
  }

  &.unread {
    background: linear-gradient(
      20deg,
      rgba(255, 171, 171, 0.53),
      rgba(224, 212, 247, 0.66) 66.81%,
      rgba(223, 215, 252, 0.59)
    );
  }

  .left {
    overflow: hidden;
    .photo {
      display: grid;
      justify-content: center;
      text-align: center;
      padding-right: 5px;
      position: relative;

      img {
        height: 45px;
        width: 45px;
        border: 3px solid $borderColor;
        border-radius: 50%;
        background-color: $alternativeBackgroundColor;

        &.unread {
          border-color: $accentColor;
        }
      }

      .unread-badge {
        background: #ff7373;
        width: 10px;
        height: 10px;
        position: absolute;
        border-radius: 50%;
        border: 2px solid #364e00;
        text-align: center;
        font-size: 0.7rem;
        font-weight: bold;
      }
    }
  }

  .right {
    overflow: hidden;

    .name {
      font-weight: bold;
    }

    .last-message {
      font-size: 0.8rem;
    }

    .date {
      font-size: 0.7rem;
      color: $secondaryTextColor;
    }
  }
}
</style>
