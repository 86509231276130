<template>
  <div
    v-if="!projects || Object.keys(projects).length === 0"
    class="centered my-2"
  >
    <div class="alert info">
      You don't have any projects yet. Please
      <a href="javascript:void(0)" @click="joinModal = true">create one</a>.
      <join-modal :show="joinModal" :onClose="() => (joinModal = false)" />
    </div>
  </div>
  <div v-else class="container">
    <div
      id="chat"
      :class="{
        active: activeChatId || $route.name === 'project-settings',
        fullscreen,
      }"
      v-cloak
    >
      <div
        v-if="!status.ok"
        class="chat-overlay error"
        :class="{ 'payment-error': status.code === 4002 }"
      >
        <div class="close-btn" @click="status.ok = true">
          <i class="bi bi-x-circle"></i>
        </div>
        <div v-if="status.code === 4002" class="payment-section">
          <h2 style="margin: 2rem 0">{{ status.message }}</h2>
          <payment-section />
        </div>
        <div v-else>
          <div class="status-message">
            {{ status.message }}
          </div>
        </div>
      </div>
      <div id="chat-list">
        <div class="top-bar">
          <div
            class="top-bar-left pointer overflow-hidden"
            @click="isMenuOpen = !isMenuOpen"
          >
            <i class="bi" :class="[isMenuOpen ? 'bi-x bi-big' : 'bi-list']"></i>
            <span
              class="title ellipsis noselect"
              :title="activeProject.bot_name"
            >
              {{ activeProject.bot_username }}
            </span>
          </div>
          <div class="pointer" @click="openSettings">
            <i class="bi bi-gear"></i>
          </div>
        </div>
        <div class="chats">
          <template v-if="isMenuOpen">
            <chat-list-project-item
              :key="project.id"
              v-for="project in projects"
              :title="project.bot_name"
              :photo="project.photo"
              @click="updateRouteByProject(project)"
              :isActive="project.id == projectId"
              :project="project"
            />
          </template>
          <template v-else>
            <chat-list-item
              :key="chat.id"
              v-for="chat in orderedChats"
              :chat="chat"
              :isActive="activeChat === chat"
              @click="updateActiveChatId(chat.id)"
            />
          </template>
        </div>
        <div class="bottom-status" v-if="bottomStatus.message">
          {{ bottomStatus.message }}
        </div>
      </div>

      <div id="chat-screen">
        <chat-right-top-bar
          :empty="$route.name === 'chat-app'"
          :title="
            $route.meta.title
              ? $route.meta.title
              : activeChat
              ? activeChat.title
              : ''
          "
          rightButtonIcon="bi-fullscreen"
          :onRightButtonClick="toggleFullscreen"
        />
        <div class="content">
          <template v-if="$route.name === 'chat-app'">
            <dashboard />
          </template>
          <router-view v-else :key="$route.fullPath" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import useSocket from '../../composables/useSocket'
import ChatListItem from '../../components/chat/ChatListItem.vue'
import ChatListProjectItem from '../../components/chat/ChatListProjectItem.vue'
import types from '../../store/chat/_types'
import api from '../../api'
import PaymentSection from '../PaymentSection.vue'
import ChatRightTopBar from '../../components/chat/ChatRightTopBar.vue'
import JoinModal from '../../components/JoinModal.vue'
import Dashboard from './Dashboard.vue'

export default {
  setup() {
    const { initWebSocket } = useSocket()
    return { initWebSocket }
  },
  components: {
    ChatListItem,
    ChatListProjectItem,
    PaymentSection,
    ChatRightTopBar,
    JoinModal,
    Dashboard,
  },
  name: 'ProjectChat',
  data() {
    return {
      isMenuOpen: false,
      message: '',
      connection: null,
      joinModal: false,
    }
  },
  computed: {
    ...mapGetters('chat', [
      'activeChat',
      'activeProject',
      'activeChatMessages',
      'orderedChats',
    ]),
    ...mapState('chat', [
      'projectId',
      'projects',
      'fullscreen',
      'activeChatId',
      'status',
      'bottomStatus',
    ]),
  },
  methods: {
    ...mapMutations('chat', {
      setProjectId: types.SET_PROJECT_ID,
      setActiveChatId: types.SET_ACTIVE_CHAT_ID,
      setConnection: types.SET_CONNECTION,
    }),
    ...mapActions('chat', [
      'getChats',
      'setActiveChatAndRoute',
      'toggleFullscreen',
      'clearChatState',
    ]),
    openSettings() {
      this.setActiveChatId()
      this.$router.push({
        name: 'project-settings',
        params: { projectId: this.projectId },
      })
    },
    async getProjects() {
      this.message = 'Setting things up...'
      try {
        const result = await api.get('/projects/')
        if (result.length === 0) {
          this.message = 'No projects found. Please create one.'
        } else {
          this.message = ''
          this.$store.dispatch('chat/setProjects', result)
        }
      } catch (error) {
        if (error.status === 403) {
          this.message = 'You need to log in to see your projects.'
        } else {
          this.message = error.message
        }
      }
    },
    updateRouteByProject(project) {
      if (project.id === this.activeProject.id) {
        return (this.isMenuOpen = false)
      }
      const route = {
        name: 'project-chat',
        params: { projectId: project.id },
      }
      this.clearChatState()
      window.location.href = this.$router.resolve(route).fullPath
    },

    updateActiveChatId(chatId) {
      this.setActiveChatAndRoute(this.activeChatId === chatId ? null : chatId)
    },
  },
  async mounted() {
    await this.getProjects()
    const localProjectId = localStorage.getItem('projectId')
    const firstProject = this.projects[Object.keys(this.projects)[0]]
    const projectId =
      this.$route.params.projectId ?? localProjectId ?? firstProject.id
    this.connection = this.initWebSocket(projectId)
    this.setProjectId(projectId)
    this.setConnection(this.connection)
    await this.getChats()
  },
  async beforeUnmount() {
    this.connection.close()
  },
}
</script>

<style lang="scss" scoped>
[v-cloak] {
  display: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.container {
  max-width: 1100px;
  margin: 0;
}

#chat {
  font-size: 0.8rem;
  display: grid;
  position: relative;
  grid-auto-flow: column;
  grid-template-columns: 1fr 3fr;

  background-color: $secondaryBackgroundColor;

  overflow: hidden;
  height: 75vh;
  margin: 30px 0;

  border: 3px solid $borderColor;
  border-radius: 0.5rem;

  &.fullscreen {
    transition: all 0.3s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }

  #chat-list {
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: 35px auto;
    overflow: hidden;

    background-color: $alternativeBackgroundColor;
    border-right: 2px solid $secondaryBackgroundColor;

    .bottom-status {
      position: absolute;
      left: 0;
      bottom: 0;
      padding: 0.3rem 1rem;
      margin: 0.3rem;
      display: block;
      color: $secondaryTextColor;
      background-color: $backgroundColor;
      border-radius: 0.5rem;
      user-select: none;
      cursor: default;
    }

    .chats {
      overflow-y: auto;
    }
  }

  #chat-screen {
    display: grid;
    position: relative;
    grid-auto-flow: row;
    // grid-template-rows: 1fr 14fr 2fr;
    grid-template-rows: 35px 14fr;
    overflow: hidden;
    // background-color: $backgroundColor;

    .content {
      overflow-y: auto;
    }
  }

  .top-bar {
    overflow: hidden;
    padding: 3px;
    background-color: $secondaryBackgroundColor;
    border-bottom: 2px solid $secondaryBackgroundColor;
    display: grid;
    grid-template-columns: 1fr 23px;
    align-content: center;
    align-items: center;
    justify-items: start;

    .top-bar-left {
      max-width: 100%;
      display: flex;
      align-content: center;
      overflow: hidden;
      align-items: center;

      i.bi {
        width: 25px;
        overflow: hidden;
      }

      .title {
        display: inline-block;
        margin: 0 0.5rem;
        font-size: 0.75rem;
        font-weight: bold;
        overflow: hidden;
      }
    }
  }

  .chat-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    /* transition: all 0.3s; */
    animation: fade-in 1s;
    -o-animation: fade-in 1s;
    -moz-animation: fade-in 1s;
    -webkit-animation: fade-in 1s;

    .close-btn {
      position: absolute;
      right: 0;
      top: 0;
      margin: 1rem 2rem 0 0;
      font-weight: bold;
      font-size: 1.2rem;
      cursor: pointer;
    }

    &.error {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #000000c4;
      color: #fff;
    }

    &.payment-error {
      display: block;
      overflow: auto;
      padding: 1rem 2rem;

      .payment-section {
        max-width: 500px;
        margin: 0 auto;
      }
    }
  }
}

// @media screen and (max-width: 1280px) {
//   #chat {
//     grid-template-columns: 1fr 2fr;
//   }
// }

@media screen and (max-width: 768px) {
  #chat {
    grid-template-columns: 1fr 0;

    &.active {
      grid-template-columns: 0 1fr;
    }
  }
}
</style>
