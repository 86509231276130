<template>
  <div id="dashboard">
    <div class="content centered">
      <i class="bi bi-gem" style="font-size: 2rem"></i>
      <h3>Welcome, {{ $store.state.user.first_name }}!</h3>
      <p>Please select a chat to start.</p>
      <small
        v-if="
          activeProject.creator_telegram_id === $store.state.user.telegram_id
        "
        >Upgrade to Pro Plan to see a handy dashboard here!</small
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters('chat', ['activeProject']),
  },
}
</script>

<style lang="scss" scoped>
#dashboard {
  display: grid;
  align-items: center;
  height: 100%;
  justify-content: center;
  padding: 0.1rem 1rem;

  .content {
    h3 {
      margin: 0.3rem 0;
      padding: 0;
    }
    p {
      margin: 0;
    }
    small {
      color: $secondaryTextColor;
    }
  }
}
</style>
